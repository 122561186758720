import React, { useState, useEffect } from "react";
import { PdfIcon, StarInputIcon, NoDocsIcon } from "../../assets";
import DocumentProgress from "./document-progress";
import { ApiService, ApiEndpoint } from "../../services/api.service";
import { useGenerateDoc } from "../../context/DocContext";
import { useChat } from "../../context/ChatContext";
import { useParams } from "react-router-dom";

interface Document {
  id: string;
  name: string;
  filePath: string;
  createdAt: string;
}

export const SidebarRight = () => {
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { hasStarted } = useGenerateDoc();
  const { generatedDocs } = useChat();
  const { chatId } = useParams();

  // useEffect(() => {
  //   console.log("Generated docs in side bar right", generatedDocs);
  //   try {
  //     console.log("docs in rightsidebar", generatedDocs);
  //     const cleanedDocuments = generatedDocs.map((doc: Document) => ({
  //       ...doc,
  //       title: doc.name.replace(/["\\/]/g, "").trim(), // Remove quotes, slashes, and trim
  //     }));
  //     setDocuments(cleanedDocuments);
  //   } catch (err) {
  //     console.log("error fetching docs", err);
  //     setDocuments([]);
  //   }
  // }, [generatedDocs]);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const response = await ApiService.get<{}, any>(
          ApiEndpoint.GetDocumentsByChat + "/" + chatId,
          {},
          true
        );
        const cleanedDocuments = response.generatedDocs.map(
          (doc: Document) => ({
            ...doc,
            title: doc.name.replace(/["\\/]/g, "").trim(), // Remove quotes, slashes, and trim
          })
        );
        setDocuments(cleanedDocuments); // Ensure response is an array
      } catch (err) {
        console.error("Error fetching documents:", err);
        // If error occurs, assume no documents are available
        setDocuments([]); // Treat as no docs instead of showing an error
      } finally {
        setLoading(false);
      }
    };

    console.log("fetching docs in sidebar right useeffect");

    fetchDocuments();
  }, [hasStarted, chatId]);

  const handleDocumentClick = async (
    documentId: string,
    documentTitle: string
  ) => {
    try {
      // Fetch the download URL from the API
      const response = await ApiService.getDocumentDownloadUrl(documentId);
      const downloadUrl = response.url;

      // Open the URL in a new browser tab
      window.open(downloadUrl, "_blank");
    } catch (error) {
      console.error("Error downloading document:", error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <>
      <div
        data-test="sidebar-container"
        className="flex w-full h-[95%] flex-col gap-[1.5rem] font-poppins overflow-y-auto overflow-x-hidden"
      >
        <h1 className="text-[1rem] text-start">Documents</h1>
        <hr className="w-full bg-gray-600 border-gray-600 border-[1px] border-solid"></hr>

        <div
          data-id="top-glow"
          className="right-glow absolute rounded-b-[50%] bg-primary-inside/50 border-stroked border-[1px] border-solid box-border w-[50rem] h-[15rem] mx-auto top-0"
        />
        <div
          data-id="top-glow"
          className="right-glow absolute rounded-b-[50%] bg-primary-inside/50 border-stroked border-[1px] border-solid box-border w-[50rem] h-[15rem] mx-auto bottom-0"
        />
        {/* uploading doc */}

        {hasStarted && <DocumentProgress />}

        {/* docs list */}

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : documents.length === 0 ? (
          <div className="flex flex-col h-full items-center justify-center">
            <NoDocsIcon className="w-[12rem] h-[12rem] mx-auto" />
            <p className="text-secondary-text italic text-center text-sm mt-6 leading-5">
              Generated Documents will appear here
            </p>
          </div>
        ) : (
          documents.map((doc) => (
            <div key={doc.id}>
              <div
                className="border border-gray-700 rounded-[0.75rem] p-[0.75rem] text-start cursor-pointer"
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.06)",
                }}
                onClick={() => handleDocumentClick(doc.id, doc.name)}
              >
                <div className="flex justify-between items-center gap-[0.5rem] mb-2">
                  <div className="flex flex-row gap-[0.5rem]">
                    <PdfIcon className="w-[1.5rem] h-[1.5rem]" />
                    <div>
                      <p className="text-[0.875rem] m-0 p-0">{doc.name}</p>
                    </div>
                  </div>
                  <StarInputIcon className="w-[1.5rem] h-[1.5rem]" />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

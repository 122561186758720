import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocalStorage } from "../../hooks/use-local-storage";
import {
  RemoveIcon,
  StarInputIcon,
  MoreInfoIcon,
  UpArrowIcon,
  DownArrowIcon,
  SearchIcon,
} from "../../assets";
import { SmartPrompt } from "../../types/smartprompt.type";

// External components
import { Divider } from "@mui/material";
import { Tune, Search, ExpandLess, ExpandMore } from "@mui/icons-material";

export function FilterDropdown({
  smartPrompts,
  isExpand,
  setSelectedCase,
}: {
  smartPrompts: SmartPrompt[];
  isExpand?: boolean;
  setSelectedCase: (usecase: {}) => void;
}) {
  const [expand, setExpand] = React.useState(isExpand);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [useCaseDropdownOpen, setUseCaseDropdownOpen] = React.useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = React.useState(false);
  const [activeCollection, setActiveCollection] = useState<string | null>(null);

  const [selectedPrompt, setSelectedPrompt] = useLocalStorage(
    "selectedPrompt",
    ""
  );
  const [selectedUsecase, setSelectedUsecase] = useLocalStorage(
    "selectedCase",
    ""
  );

  const collectionRefs = useRef<Map<string, HTMLDivElement>>(new Map());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const collectionName = entry.target.id;

          if (entry.isIntersecting) {
            setActiveCollection(collectionName);
          } else {
            if (activeCollection === collectionName) {
              setActiveCollection(null);
            }
          }
        });
      },
      {
        rootMargin: "0px 0px -80% 0px",
      }
    );

    collectionRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      collectionRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
      observer.disconnect();
    };
  }, [activeCollection]);

  useEffect(() => {
    if (isExpand) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [isExpand]);

  // Filter usecases based on search query
  const filteredUsecases = smartPrompts.filter((usecase) =>
    usecase.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex flex-col w-full relative">
      {expand ? (
        <DownArrowIcon
          onClick={() => setExpand(false)}
          className="w-10 absolute right-0 -top-8 h-10 text-lg font-bold"
        />
      ) : (
        <UpArrowIcon
          onClick={() => setExpand(true)}
          className="w-10 absolute right-0 -top-8 h-10 text-lg font-bold"
        />
      )}
      {/* parent container */}
      <div
        className="flex relative flex-col py-[1.12rem] rounded-[0.5rem] px-[0.75rem] bg-input mx-auto md:w-full w-[calc(100vw-20px)]
   border border-border-100 box-border
    "
        style={{ border: "1px solid #22222B" }}
      >
        <div className="flex items-center justify-between m-0 p-0 h-auto ">
          <div className="flex items-center gap-1">
            <StarInputIcon className="w-5 h-5 text-xl font-bold" />
            <h2 className="text-text-color m-0 p-0 tracking-[-0.035rem] text-sm">
              Smart Prompt
            </h2>
          </div>
          <div className="flex items-center gap-2 ">
            {expand && (
              <div
                className="flex  items-center gap-2 border-b border-border-200
              pb-[0.38rem]
              "
                style={{ borderBottom: "1px solid #262830" }}
              >
                <SearchIcon className="w-5 h-5 text-lg font-bold " />
                <input
                  type="text"
                  placeholder="Search here"
                  className="text-text-color bg-transparent border-none focus:outline-none"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            )}
            <MoreInfoIcon className="w-5 h-5 text-lg font-bold" />
          </div>
        </div>

        {/* expand container */}
        {expand && (
          <div>
            <Divider
              className="my-2"
              sx={{
                borderColor: "#262830",
                borderWidth: "1px",
                marginY: "1rem",
              }}
            />
            {/* filter container */}
            <div className="flex gap-2 flex-wrap max-h-[6.375rem] overflow-y-auto">
              {filteredUsecases.map((usecase, index) => (
                <div
                  key={index}
                  className={`flex gap-[0.38rem] justify-center items-center rounded-[6.25rem] border border-secondary-text
                  px-[0.88rem] py-[0.38rem] text-secondary-text text-sm
                    ${selectedUsecase === usecase.id ? "text-stroked " : ""}
                  `}
                  style={{
                    border:
                      selectedUsecase === usecase.id
                        ? "1px solid #6381FC"
                        : "1px solid #707178",
                  }}
                >
                  <input
                    type="radio"
                    className="custom-radio"
                    id={usecase.id}
                    onClick={() => {
                      setSelectedCase(usecase);
                      setSelectedUsecase(usecase.id);
                      setSelectedPrompt(usecase.instruction);
                    }}
                    checked={selectedUsecase === usecase.id}
                    name="usecase" // Ensure all radio buttons have the same name
                    value={usecase.id}
                  />
                  <label htmlFor={usecase.id} className={` text-sm  `}>
                    {usecase.title}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

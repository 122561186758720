import PersonIcon from "@mui/icons-material/Person";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DashMenuIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  ImgLogo,
  LogoutIcon,
  MoreIcon,
  PlusIcon,
  SettingsIcon,
} from "../../assets";
import { ConfirmationDialog } from "../simple-dialog/dialog";
import { ChatService } from "../../services/chat.service";
import { OpenAIApiService } from "../../services/openai.service";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { AppContext } from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";
import { Chat } from "../../types/chat.types";
import { useChat } from "../../context/ChatContext";
import { Link } from "react-router-dom";
import { useChats } from "../../hooks/user-fetch-chats.hook";

import {
  format,
  isToday,
  isYesterday,
  subDays,
  subMonths,
  isThisWeek,
  startOfWeek,
  differenceInCalendarWeeks,
} from "date-fns";
import { Loader } from "../ui/Loader";

interface GroupedChats {
  today: Chat[];
  yesterday: Chat[];
  thisWeek: Chat[];
  lastWeek: Chat[];
  older: Chat[];
}

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNewChat: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedChat: (value: React.SetStateAction<string | null>) => void;
  setRightSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Sidebar: React.FC<SidebarProps> = ({
  sidebarOpen,
  setSidebarOpen,
  setNewChat,
  setSelectedChat,
  setRightSidebarOpen,
}) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const {
    activeChat,
    setActiveChat,
    chats,
    setChats,
    createChat,
    fetchMessages,
    setMessages,
  } = useChat();
  const [activeThread, setActiveThread] = useLocalStorage("activeThread", "");
  const [activeAssistant, setActiveAssistant] = useLocalStorage(
    "activeAssistant",
    ""
  );
  const [activeVectorStore, setActiveVectorStore] = useLocalStorage(
    "activeVectorStore",
    ""
  );

  const { data: fetchedChats, isLoading, error } = useChats();

  const [errorMessage, setErrorMessage] = useState("");
  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null); // Ref for sidebar
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown
  const dropdownRef2 = useRef<HTMLDivElement | null>(null); // Ref for second dropdown
  const [showDropdown2, setShowDropdown2] = useState<boolean | null>(null);
  const appContext = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const handleClickOpen = (item: string) => {
    setItemToDelete(item);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    setOpen(false);
    if (confirmed && itemToDelete) {
      // handleDeleteForm(itemToDelete);
    }
    setItemToDelete(null);
  };

  useEffect(() => {
    if (fetchedChats) {
      setChats(fetchedChats);
    }
  }, [fetchedChats, setChats]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(null);
        setHoveredItem(null);
      }

      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }

      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSidebarOpen]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  function isLastWeek(date: Date): boolean {
    const now = new Date();
    const currentWeekStart = startOfWeek(now, { weekStartsOn: 1 });
    const targetWeekStart = startOfWeek(date, { weekStartsOn: 1 });

    return differenceInCalendarWeeks(currentWeekStart, targetWeekStart) === 1;
  }

  function groupChatsByDate(chats: Chat[]): GroupedChats {
    const groupedChats: GroupedChats = {
      today: [],
      yesterday: [],
      thisWeek: [],
      lastWeek: [],
      older: [],
    };

    chats.forEach((chat) => {
      const chatDate = new Date(chat.createdAt); // Convert to Date object here
      if (isToday(chatDate)) {
        groupedChats.today.push({ ...chat, createdAt: chatDate });
      } else if (isYesterday(chatDate)) {
        groupedChats.yesterday.push({ ...chat, createdAt: chatDate });
      } else if (isThisWeek(chatDate, { weekStartsOn: 1 })) {
        groupedChats.thisWeek.push({ ...chat, createdAt: chatDate });
      } else if (isLastWeek(chatDate)) {
        groupedChats.lastWeek.push({ ...chat, createdAt: chatDate });
      } else {
        groupedChats.older.push({ ...chat, createdAt: chatDate });
      }
    });

    (Object.keys(groupedChats) as (keyof GroupedChats)[]).forEach((key) => {
      groupedChats[key].sort((a: Chat, b: Chat) =>
        a.createdAt instanceof Date && b.createdAt instanceof Date
          ? b.createdAt.getTime() - a.createdAt.getTime()
          : 0
      );
    });

    return groupedChats;
  }

  function formatGroupName(group: string) {
    switch (group) {
      case "today":
        return "Today";
      case "yesterday":
        return "Yesterday";
      case "thisWeek":
        return "This Week";
      case "lastWeek":
        return "Last Week";
      case "older":
        return "Older";
      default:
        return group;
    }
  }

  const createNewChat = async () => {
    setIsLoadingChat(true);
    setMessages([]);

    // Check if the current active chat is empty
    const activeChatObj = chats.find((chat) => chat.id === activeChat);
    if (
      activeChatObj &&
      activeChatObj.messages &&
      activeChatObj.messages.length === 0
    ) {
      // Optionally, you can show a warning or message to the user here
      console.warn(
        "Cannot create a new chat. The current active chat is empty."
      );
      setIsLoadingChat(false);
      return;
    }
    const newChat = await createChat();
    setChats([...chats, newChat]);
    setSelectedChat(newChat.id);
    setActiveChat(newChat.id);
    setIsLoadingChat(false);
  };

  const chatsWithDates = chats.map((c) => ({
    ...c,
    createdAt: new Date(c.createdAt),
  }));

  const groupedChats = groupChatsByDate(chatsWithDates);

  return (
    <>
      <div
        data-test="sidebar-container"
        className="flex w-full h-[95%] flex-col gap-[1.5rem] font-poppinsoverflow-x-hidden"
      >
        <div
          data-id="right-glow"
          className="[ right-glow absolute bg-primary-inside/50 w-[17.438rem] h-[calc(100vh-20px)] z-[-2] top-0 right-[5rem]"
        />
        <nav className="flex justify-center items-center h-16">
          <ImgLogo
            data-id="nav-logo"
            className="w-[9.75rem] h-[2rem]"
            onClick={() => {
              setNewChat(true);
            }}
          />
        </nav>

        <hr className="w-full  bg-gray-600 border-gray-600 border-[1px] border-solid"></hr>

        <button
          data-test="generate-document-button"
          className="flex p-[0.5rem] px-[1.5rem] justify-center items-center gap-[0.125rem] self-stretch rounded-[6.25rem] border border-stroked bg-primary-inside/65
        text-white font-poppins text-[0.875rem] font-normal  leading-normal tracking-[-0.035rem] hover:cursor-pointer
        "
          onClick={createNewChat}
          disabled={isLoadingChat}
        >
          <div className="flex items-center justify-center gap-[0.25rem]">
            {isLoadingChat ? (
              <div className="flex items-center">
                <Loader />
              </div>
            ) : (
              <>
                <PlusIcon className="w-[1rem] h-[1.2rem] text-white border stroked-white" />
                <span>New Case</span>
              </>
            )}
          </div>
        </button>
        <button
          data-test="new-case-button"
          className="flex md:hidden p-[0.5rem] px-[1.5rem] justify-center items-center gap-[0.125rem] self-stretch rounded-[6.25rem] border border-stroked bg-primary-inside/65
        text-white font-poppins text-[0.875rem] font-normal  leading-normal tracking-[-0.035rem] hover:cursor-pointer
        "
          onClick={() => {
            setRightSidebarOpen(true);
            setSidebarOpen(false);
          }}
          disabled={isLoadingChat}
        >
          Show Docs
        </button>

        {/* chat items */}
        <div className="flex flex-grow flex-col gap-[1.5rem] overflow-y-scroll overflow-x-hidden">
          {Object.entries(groupedChats).map(
            ([group, chats]) =>
              chats.length > 0 && (
                <div key={group} className=" ">
                  <p className="text-secondary-text m-0 p-0 font-poppins text-[0.875rem] text-start font-medium leading-normal tracking-[-0.035rem]">
                    {formatGroupName(group)}
                  </p>
                  <div
                    data-test="chats-container"
                    className="flex flex-col gap-[0.5rem]"
                  >
                    {chats.map((chat: Chat) => (
                      <div className="relative self-stretch " key={chat.id}>
                        <Link to={`/chat/${chat.id}`}>
                          <div
                            onClick={async () => {
                              console.log("chat", chat);
                              setSelectedChat(chat.id);
                              setActiveChat(chat.id);
                              setActiveAssistant(chat.assistantId);
                              setActiveVectorStore(chat.vectorStoreId);
                              console.log("vectorStoreId", chat.vectorStoreId);
                              setActiveThread(chat.threadId);
                              // await fetchMessages();
                            }}
                            data-test="chat-container"
                            className={`relative flex p-[0.625rem] items-center h-[1.5rem] text-start gap-[0.625rem] self-stretch rounded-[0.5rem] border border-stroked transition-colors duration-300 ${
                              hoveredItem === chat.id
                                ? "border-r-4 border-r-stroked bg-gray-300 cursor-pointer"
                                : "border-r-4 border-transparent"
                            }
                          ${activeChat === chat.id ? "bg-gray-300" : ""}
                          `}
                            onMouseEnter={() => setHoveredItem(chat.id)}
                            onMouseLeave={() => {
                              showDropdown !== chat.id && setHoveredItem(null);
                            }}
                          >
                            <div className="flex items-center w-[3px] justify-center">
                              {hoveredItem === chat.id && (
                                <hr className="w-[3px] h-[1.5rem] bg-stroked rounded-xl"></hr>
                              )}
                            </div>

                            <div
                              className="text-[0.875rem] px-2 text-text-color hover:border-r-4 border-r-stroked flex-grow font-poppins font-normal leading-normal tracking-[-0.035rem] break-words whitespace-nowrap overflow-hidden text-clip transition-all duration-300 ease-in-out"
                              title={chat.title}
                            >
                              {chat.title}
                            </div>

                            <div className="w-[1rem] flex items-center justify-center">
                              {hoveredItem === chat.id && (
                                <DashMenuIcon
                                  className="w-[1rem] h-[1rem]"
                                  onClick={() => {
                                    setShowDropdown(chat.id);
                                  }}
                                ></DashMenuIcon>
                              )}
                            </div>
                          </div>
                        </Link>
                        {showDropdown === chat.id && (
                          <div
                            ref={dropdownRef}
                            className="absolute top-[2.5rem] right-[1.5rem] flex flex-col gap-[0.5rem] bg-[#2A2A35] p-[0.5rem] px-[0.75rem] rounded-[0.5rem] z-10"
                          >
                            <div
                              data-test="edit-chat-button"
                              className="flex pt-[0.5rem] pr-[2rem] pb-[0.5rem] pl-[0.5625rem] items-center gap-[0.375rem] self-stretch border hover:border-stroked hover:bg-hover hover:cursor-pointer"
                            >
                              <EditIcon className="w-[1.25rem] h-[1.25rem]"></EditIcon>
                              <p className="text-text-color font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] p-0 m-0">
                                Edit
                              </p>
                            </div>
                            <div
                              data-test="download-chat-button"
                              className="flex pt-[0.5rem] pr-[2rem] pb-[0.5rem] pl-[0.5625rem] items-center gap-[0.375rem] self-stretch border hover:border-stroked hover:bg-hover hover:cursor-pointer"
                            >
                              <DownloadIcon className="w-[1.25rem] h-[1.25rem]"></DownloadIcon>
                              <p className="text-text-color font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] p-0 m-0">
                                Download
                              </p>
                            </div>
                            <div
                              data-test="delete-chat-button"
                              className="flex pt-[0.5rem] pr-[2rem] pb-[0.5rem] pl-[0.5625rem] items-center gap-[0.375rem] self-stretch border hover:border-stroked hover:bg-hover hover:cursor-pointer"
                              onClick={() => handleClickOpen(chat.id)}
                            >
                              <DeleteIcon className="w-[1.25rem] h-[1.25rem]"></DeleteIcon>
                              <p className="text-warning font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] p-0 m-0">
                                Delete
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
          {errorMessage && (
            <div>
              <p className="text-[0.825rem] ">Failed to load chats</p>
              <button className="rounded-[6.25rem] py-[0.4rem] px-[1rem] bg-primary-inside text-white border border-gray-50 hover:cursor-pointer">
                Retry
              </button>
            </div>
          )}
        </div>

        {/* user card */}
        <div className="  mb-[1rem] md:mb-0">
          <hr className="w-full  bg-gray-600 border-gray-600 border-[1px] border-solid"></hr>

          {/* user card */}
          <div className="flex items-center justify-center space-x-4  border-t border-gray-700 text-white rounded-lg">
            <PersonIcon className="w-10 h-10 p-2 bg-gray-800 rounded-full" />
            <div className="text-start">
              <div className="text-base font-semibold">
                {user?.firstName + " " + user?.lastName}
              </div>
              <a
                href="#"
                className="text-blue-400 hover:underline text-[0.875rem] "
              >
                Upgrade Plan
              </a>
            </div>
            <div
              className=""
              onClick={() => {
                setShowDropdown2(!showDropdown2);
              }}
            >
              <MoreIcon />
            </div>
            {showDropdown2 && (
              <div
                ref={dropdownRef2}
                className="absolute z-10  h-fit bottom-16 tracking-[-0.0175rem] flex flex-col gap-[0.75rem]  bg-[#2A2A35] p-2 rounded-lg"
              >
                <div className="flex p-0 px-[1.25rem] hover:cursor-pointer m-0 justify-start items-center gap-[0.375rem]  text-white hover:bg-[#6381FC]">
                  🚀
                  <p className="text-sm pl-1">Upgrade Plan</p>
                </div>
                <div className="flex p-0 px-[1.25rem] hover:cursor-pointer m-0 justify-start items-center gap-[0.375rem]  text-white hover:bg-[#6381FC]">
                  <SettingsIcon />
                  <p className="text-sm pl-1">Settings</p>
                </div>
                <div
                  className="flex p-0 px-[1.25rem] hover:cursor-pointer m-0 justify-start items-center gap-[0.375rem]  text-white hover:bg-[#6381FC]"
                  onClick={handleLogout}
                >
                  <LogoutIcon />
                  <p className="text-sm pl-1">Logout</p>
                </div>
              </div>
            )}
          </div>
          <ConfirmationDialog
            open={open}
            title="Confirm Deletion"
            content="Are you sure you want to delete this item?"
            onClose={handleClose}
          />
        </div>
      </div>
    </>
  );
};

import React, { useState, useContext, useEffect } from "react";
import { PercentageIndicator } from "../../components/progress/percentage-indicator.component";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { User } from "../../types/user.type";
import { ImgGenerate, ImgLineHorizontal } from "../../assets/index";
import { generateDocument } from "../../services/generate-document";

interface DocumentFormProps {
  user: User | null;
  tempDocument: string;
  setTempDocument: React.Dispatch<React.SetStateAction<string>>;
}

export const DocumentForm = ({
  tempDocument,
  setTempDocument,
}: DocumentFormProps) => {
  const [generatedDocument, setGeneratedDocument] = useState("");
  const [error, setError] = useState("");
  const [tempCity, setTempCity] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const navigate = useNavigate();

  const appContext = useContext(AppContext);

  const handleGenerate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !tempDocument ||
      !tempCity ||
      tempDocument.trim() === "" ||
      tempCity.trim() === ""
    ) {
      setError("Please enter a document name and city");
      return;
    }

    generateDocument({
      tempDocument,
      tempCity,
      setError,
      setDocument,
      setCity,
      setLoading,
      setGeneratedDocument,
      setDocumentTitle,
      setQuestionSections,
      setFormData,
      navigate,
    });

    // navigate("/document/form");
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (loading) {
      setLoadingPercentage(0); // Reset loading percentage
      interval = setInterval(() => {
        setLoadingPercentage((prev) => (prev >= 99 ? 99 : prev + 1)); // Increment by 1 up to 99%
      }, 60); // Update every 60ms
    } else {
      clearInterval(interval);
      setLoadingPercentage(0); // Set to 100% when loading is complete
    }

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [loading]);

  const {
    city,
    documentContent,
    setCity,
    setDocument,
    setQuestionSections,
    setDocumentTitle,
    setFormData,
    storedDocs,
  } = appContext!!;

  return (
    <div
      data-test="document-form-container"
      className="max-w-[45rem]  [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-center mx-[1rem] md:mx-auto p-[1.5rem] gap-[1rem] text-[0.875rem]  mt-[1rem] text-stroked"
    >
      {loading && (
        <PercentageIndicator
          show={loading}
          loadedPercentage={loadingPercentage}
          label="Generating Prerequisites"
          showPercent
        />
      )}
      <div
        className={`${
          loading ? "blur-md pointer-events-none " : ""
        }  max-w-[43.1875rem]  px-[1.5rem] `}
      >
        <div className="relative tracking-[-0.04em] font-medium mb-4">
          Available in the US only
        </div>
        <form
          data-test="document-form"
          onSubmit={handleGenerate}
          className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-left text-[1.125rem] text-text-color z-1 "
        >
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] ">
            <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
            <div className="self-stretch flex flex-col md:flex-row items-center   overflow-hidden justify-start gap-[0.5rem] ">
              <div className="max-w-[5.563rem] relative tracking-[-0.04em] font-medium flex items-center h-[2rem] shrink-0">
                Generate
              </div>
              <div className="flex-1 w-[15rem] rounded-lg bg-gray-200 flex flex-row items-center justify-start py-[0.625rem] px-[1rem] text-[1rem] text-secondary-text">
                <input
                  data-test="document-name-input"
                  value={tempDocument}
                  required
                  onChange={(e) => {
                    setTempDocument(e.target.value);
                    setError("");
                  }}
                  className="font-medium font-poppins text-lg flex items-center h-[1.5rem] shrink-0 bg-transparent text-white outline-none"
                  placeholder="Document"
                />
              </div>
              <div className="relative tracking-[-0.04em] font-medium flex items-center h-[2rem] shrink-0">
                For
              </div>
              <div className="flex-1 w-[15rem] rounded-lg bg-gray-200 flex flex-row items-center justify-start py-[0.625rem] px-[1rem] text-[1rem] text-secondary-text">
                <input
                  data-test="document-city-input"
                  value={tempCity}
                  onChange={(e) => {
                    setTempCity(e.target.value);
                    setError("");
                  }}
                  className="font-medium font-poppins text-lg flex items-center h-[1.5rem] shrink-0 bg-transparent text-white outline-none"
                  placeholder="City"
                  required
                />
              </div>
            </div>
            <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
          </div>
          <div className="self-stretch flex  items-center justify-center text-center text-[1rem]  ">
            <div className="flex flex-col items-center justify-center gap-[0.375rem]">
              <button
                data-test="document-generate-button"
                disabled={loading}
                className="flex-1 rounded-81xl bg-primary-inside border-stroked border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[0.5rem] pl-[1.5rem] pr-[2rem] gap-[0.25rem] outline-none hover:bg-primary-inside/40 transition-colors duration-300 cursor-pointer"
              >
                <ImgGenerate className="w-[1.5rem] relative h-[1.5rem] overflow-hidden shrink-0" />
                <div className="font-medium text-[1rem] text-white font-poppins">
                  {loading ? "Generating..." : "Generate Form"}
                </div>
              </button>

              {/* not present in figma */}
              {/* <div style={{ display: loading ? 'none' : 'flex' }} className="flex-1 rounded-81xl bg-gray-200 border-darkslategray border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] text-white cursor-pointer border-solid border-[1px] border-primary-inside hover:bg-primary-inside/40 transition-colors duration-300" onClick={() => { setTempCity(''); setTempDocument('') }}>
                    <div className="relative tracking-[-0.04em] font-medium ">Clear</div>
                  </div> */}
              {error && (
                <div
                  data-test="document-generate-error"
                  className="text-red-500 align-center"
                >
                  {error}
                </div>
              )}
            </div>

            {/* <div className="hidden md:flex rounded-81xl bg-gray-200 border-darkslategray border-[1px] border-solid overflow-hidden flex-col items-center justify-center py-[0.5rem] px-[1.25rem]">
                  <div className="relative tracking-[-0.04em] font-medium">Share</div>
                </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

import MenuIcon from "@mui/icons-material/Menu";

import { useContext, useEffect, useRef, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { StarIcon, ExportIcon } from "../../assets/index";
import { DocumentForm } from "../../components/document/document-form";
import { FilterDropdown } from "../../components/document/smart-prompt-form";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AppContext } from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";
import { SidebarRight } from "../../components/sidebar/sidebar-right";
import { Chat as ChatComponent } from "../../components/chat/chat";
import { Divider } from "@mui/material";
import { Input } from "../../components/chat/input";
import { Chat, Message } from "../../types/chat.types";
import { useChat } from "../../context/ChatContext";
import { useParams } from "react-router-dom";
import { useFetchSmartPrompts } from "../../hooks/use-fetch-smartprompts";
import { SmartPrompt } from "../../types/smartprompt.type";
// import PDFViewer from "../../components/pdf/pdf-viewer";

interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface History {
  title: string;
  id: string;
}

export function DashboardPage() {
  const { chats, activeChat, setActiveChat, messages, fetchMessages } =
    useChat();
  const { chatId } = useParams();

  const navigate = useNavigate();
  const { data, isLoading } = useFetchSmartPrompts();
  const [smartprompts, setSmartPrompts] = useState<SmartPrompt[]>([]);
  const [newDocument, setNewDocument] = useState("");
  const [smartPrompt, setSmartPrompt] = useState("");
  // chat states
  const [newChat, setNewChat] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);

  const [userPrompt, setUserPrompt] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedDocTitle, setSelectedDocTitle] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar visibility
  // const [rightSidebarOpen, setRightSidebarOpen] = useState(false); // State for right sidebar visibility
  const [tempCity, setTempCity] = useState("");
  const [selectedCase, setSelectedCase] = useState({});
  const [showDropdown, setShowDropdown] = useState<string | null>();
  const [history, setHistory] = useState<History[]>([]);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [tempDocument, setTempDocument] = useState("");
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null); // State for selected document
  const [selectedDocUrl, setSelectedDocUrl] = useState<string | null>(null); // State for selected markdown
  const documentRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for file input
  const sidebarRef = useRef<HTMLDivElement | null>(null); // Ref for sidebar
  const rightSidebarRef = useRef<HTMLDivElement | null>(null); // Ref for left sidebar
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown
  const dropdownRef2 = useRef<HTMLDivElement | null>(null); // Ref for second dropdown
  const [showDropdown2, setShowDropdown2] = useState<boolean | null>();
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const filterDropdownRef = useRef<HTMLDivElement | null>(null);

  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);

  const [labels, setLabels] = useState<string[]>([]);
  const [showChat, setShowChat] = useState<boolean>(false);

  const { user } = useAuth();
  const appContext = useContext(AppContext);
  const { rightSidebarOpen, setRightSidebarOpen } = appContext!!;

  // Fetch smart prompts
  useEffect(() => {
    if (data) {
      setSmartPrompts(data);
    }
  }, [data]);

  useEffect(() => {
    if (chatId) {
      console.log("triggered fetchMessages in chat");
      fetchMessages();
      return;
    }
  }, [chatId]);

  // trigger the chat component to show when there are messages
  useEffect(() => {
    setShowChat(messages.length > 0);
  }, [chats, activeChat]);

  useEffect(() => {
    if (!user) {
      navigate("/"); // Redirect to home or login page if not authenticated
    }
  }, [user, navigate]); // Add isAuthenticated and navigate as dependencies

  useEffect(() => {
    if (smartPrompt) {
      setSmartPrompt("");
    }
  }, [newDocument]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(null);
        setHoveredItem(null);
      }

      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }

      if (
        rightSidebarRef.current &&
        !rightSidebarRef.current.contains(event.target as Node)
      ) {
        setRightSidebarOpen(false);
      }

      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target as Node)
      )
        if (
          dropdownRef2.current &&
          !dropdownRef2.current.contains(event.target as Node)
        ) {
          setShowDropdown2(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleGlobalDragOver = (event: DragEvent) => {
      event.preventDefault(); // allow dropping
    };

    const handleGlobalDrop = (event: DragEvent) => {
      event.preventDefault();

      if (!event.dataTransfer) {
        return; // If dataTransfer is null, just return early.
      }
      const droppedFiles = Array.from(event.dataTransfer?.files);
      if (droppedFiles.length > 0) {
        // Merge new dropped files with existing files
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
      }
    };

    window.addEventListener("dragover", handleGlobalDragOver);
    window.addEventListener("drop", handleGlobalDrop);

    return () => {
      window.removeEventListener("dragover", handleGlobalDragOver);
      window.removeEventListener("drop", handleGlobalDrop);
    };
  }, []);

  function focusChatInput() {
    inputRef.current?.focus();
  }

  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div
        data-id="dashboard-container"
        className="w-full flex flex-row h-screen max-w-full relative overflow-hidden text-[1.25rem] text-text-color font-poppins"
      >
        {/* Toggle button for sidebar */}
        <div
          className={`md:hidden p-4  h-fit absolute top-4 left-4  z-[100] ${
            sidebarOpen ? "hidden" : ""
          }`}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <MenuIcon
            sx={{
              color: "white",
              fontSize: 40,
            }}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
        </div>

        {/* side bar */}
        <div
          ref={sidebarRef}
          className={`md:bg-gray-500  h-full md:h-auto  min-h-[90%]  md:min-h-[calc(100vh)-30px] bg-primary-text border-r-4 pt-[2.5rem] md:max-w-[calc(12vw)] flex flex-col items-center px-[1.25rem] border-r-stroked transition-transform duration-300 ${
            sidebarOpen ? "translate-x-0 " : "-translate-x-full"
          } md:translate-x-0 fixed md:relative z-50 md:z-auto`}
        >
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setNewChat={setNewChat}
            setRightSidebarOpen={setRightSidebarOpen}
            setSelectedChat={(chatId) => {
              setSelectedChat(chatId);
              focusChatInput(); // focus the input after selecting a chat
            }}
          />
        </div>

        {/* main screen */}
        <div className="flex-1 relative  justify-center items-center flex flex-col h-screen overflow-hidden">
          <div
            data-id="top-glow"
            className="right-glow absolute rounded-b-[50%] bg-primary-inside/50  border-stroked border-[1px] border-solid box-border w-[50rem] h-[10rem] mx-auto top-0 "
          />
          <div
            data-id="right-glow"
            className="[ right-glow absolute bg-primary-inside/50 w-[calc(50vw)] h-[calc(60vh)] z-[-10] bottom-[-2rem] left-auto right-auto"
          />

          <div className="flex-1 overflow-y-auto px-[1.5rem] w-full flex flex-col">
            <div
              className={`flex flex-col items-center justify-center ${
                !showChat ? "my-40" : "my-4"
              }`}
            >
              <div
                className={`flex-1 flex flex-col items-center justify-center text-[1.125rem] ${
                  newDocument ? "animate-slide-up" : ""
                }
                  ${showChat ? "hidden" : ""}
                  mb-4`} // Changed from mb-8 to mb-4
              >
                <div className="rounded-[30px] text-sm md:text-xl bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem]">
                  <div className="relative tracking-[-0.04em] font-medium">
                    AI For Documents
                  </div>
                </div>
                <div className="max-w-3xl w-full flex flex-col items-center justify-start gap-[0.5rem] text-[1.3rem] md:text-[3.5rem] mt-[1rem]">
                  <div className="text-center tracking-[-0.06em] font-medium">
                    <span>Generate</span>
                    <span className="text-white">{` `}</span>
                    <span className="text-transparent !bg-clip-text [background:linear-gradient(91.15deg,_#707178,_#484950)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">{`Legal `}</span>
                    <span>Documents</span>
                  </div>
                  <div className="text-center text-sm md:text-[1.25rem] tracking-[-0.04em] font-medium text-secondary-text mt-[0.5rem]">
                    Legal documents Personalized for your needs.
                  </div>
                </div>
              </div>
            </div>
            {/* display chat component here */}
            {showChat && (
              <>
                <hr className="w-full -mt-6 mb-14 bg-divider border-divider border-[1px] border-solid"></hr>{" "}
                {/* Changed from mt-2 mb-8 */}
                <div className="w-full flex flex-col items-center justify-center gap-[2rem] ">
                  <ChatComponent />
                </div>
              </>
            )}
          </div>
          <div className="w-[90%]  flex justify-center mx-auto pb-4 ">
            <div className="w-full gap-3 flex justify-center flex-col ">
              {!isLoading ? (
                <FilterDropdown
                  isExpand={!showChat}
                  smartPrompts={smartprompts}
                  setSelectedCase={setSelectedCase}
                />
              ) : (
                <p>Loading...</p>
              )}
              <Input
                ref={inputRef}
                files={files}
                onFilesChange={setFiles}
                selectedCase={selectedCase}
                setSelectedCase={setSelectedCase}
              />
            </div>
          </div>

          {/* display document */}
          {selectedDocument && (
            <div className="min-h-[calc(100vh)] w-full pt-[1.5rem] px-[2.5rem]">
              <div className="  w-full flex justify-center md:justify-between items-center   ">
                <h1 className="text-[var(--Text-Color,#FDFDFD)] text-[1.4rem] w-[calc(70vw)] text-center md:text-start pl-[1rem] font-poppins md:text-[2rem] text-wrap font-semibold leading-normal tracking-[-0.08rem]">
                  {selectedDocTitle}
                </h1>
                <div className="absolute bottom-0 md:relative flex items-center justify-center gap-x-[1.25rem]">
                  <button
                    className="flex bg-transparent text-white  "
                    onClick={() => {}}
                  ></button>
                  <button
                    className="flex p-[0.5rem] px-[1.5rem] justify-center items-center gap-[0.125rem] self-stretch rounded-[6.25rem] border border-stroked bg-[rgba(99,129,252,0.12)]
                        text-stroked font-poppins text-[0.875rem] font-normal  leading-normal tracking-[-0.035rem] hover:cursor-pointer
                        "
                    onClick={() => {}}
                  >
                    <div className="flex items-center justify-center gap-[0.25rem]">
                      <ExportIcon className="w-[1rem] h-[1rem]" />
                      Export
                    </div>
                  </button>
                </div>
              </div>
              <hr className="w-full  bg-gray-200 border-gray-600 border-[1px] border-solid mb-[1.5rem]"></hr>
              <div className="h-full overflow-y-scroll scrollbar-hide rounded-lg w-full">
                {/* <DocumentPreview
                  ref={documentRef}
                  markdownContent={selectedMarkdown}
                /> */}
                <object
                  data={selectedDocUrl || ""}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Alternative text - include a link{" "}
                    <a href="http://africau.edu/images/default/sample.pdf">
                      to the PDF!
                    </a>
                  </p>
                </object>
              </div>
            </div>
          )}
        </div>

        {/* right sidebar */}
        <div
          ref={rightSidebarRef}
          className={`md:bg-gray-500 h-full md:h-auto min-h-[90%]  md:min-h-[calc(100vh)-30px] bg-[#151619] border-l-4 pt-[2.5rem] md:max-w-[calc(16vw)] flex flex-col items-center px-[1.25rem] border-l-stroked transition-transform duration-300 ${
            rightSidebarOpen ? "translate-x-0 " : "translate-x-full"
          } md:translate-x-0 fixed md:relative z-40 md:z-0 right-0`}
        >
          <SidebarRight />
        </div>
      </div>
    </>
  );
}

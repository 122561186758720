// src/context/AppContext.tsx
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/use-local-storage";
import { User } from "../types/user.type";

interface AppContextProps {
  city: string;
  documentContent: string;
  questionSections: QuestionSection[];
  documentTitle: string;
  generatedMarkdown: string;
  formData: { [key: number]: { [questionIndex: number]: string | boolean } };
  currentStep: number;
  user: User | null;
  storedDocs: Document[];
  setCity: (city: string) => void;
  setRightSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rightSidebarOpen: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setDocument: (document: string) => void;
  // setStoredDocs: (documents: Document[]) => void;
  setGeneratedMarkdown: (generatedMarkdown: string) => void;
  setQuestionSections: (sections: QuestionSection[]) => void;
  setDocumentTitle: (title: string) => void;
  setFormData: React.Dispatch<
    React.SetStateAction<{
      [key: number]: { [questionIndex: number]: string | boolean };
    }>
  >;
  setCurrentStep: (currentStep: number) => void;
}

export interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

export interface Document {
  id: string;
  city: string;
  document: string;
  documentTitle: string;
  questionSections: QuestionSection[];
  generatedMarkdown: string;
  formData: { [key: number]: { [questionIndex: number]: string | boolean } };
  createdAt: Date;
}
export interface QuestionSection {
  sectionTitle: string;
  questions: Question[];
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [city, setCity] = useLocalStorage<string>("city", "");
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false); // State for right sidebar visibility

  const [document, setDocument] = useLocalStorage<string>("document", "");
  const [currentStep, setCurrentStep] = useLocalStorage<number>(
    "currentStep",
    0
  );
  const [questionSections, setQuestionSections] = useLocalStorage<
    QuestionSection[]
  >("questionSections", []);
  const [documentTitle, setDocumentTitle] = useLocalStorage<string>(
    "documentTitle",
    ""
  );
  const [generatedMarkdown, setGeneratedMarkdown] = useLocalStorage<string>(
    "generatedMarkdown",
    ""
  );
  const [formData, setFormData] = useLocalStorage<{
    [key: number]: { [questionIndex: number]: string | boolean };
  }>("formData", {});
  const [storedDocs, setStoredDocs] = useLocalStorage<Document[]>(
    "storedDocs",
    []
  );
  // Authentication state
  const [user, setUser] = useLocalStorage<User | null>("user", null);
  const isAuthenticated = Boolean(user);

  // Reset currentStep on page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("currentStep", "0");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return (
    <AppContext.Provider
      value={{
        city,
        documentContent: document,
        questionSections,
        documentTitle,
        formData,
        generatedMarkdown,
        currentStep,
        storedDocs,
        user,
        setUser,
        setCity,
        setDocument,
        setQuestionSections,
        setDocumentTitle,
        setFormData,
        setGeneratedMarkdown,
        setRightSidebarOpen,
        rightSidebarOpen,
        // setStoredDocs,
        setCurrentStep,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { HomePage } from "./pages/home/home";
import { FormPage } from "./pages/form/form";
import { PdfPage } from "./pages/pdf/pdf";
import { LoginPage } from "./pages/login/login";
import { SignupPage } from "./pages/signup/signup";
import { DashboardPage } from "./pages/dashboard/dashboard";
import { ForgetPasswordPage } from "./pages/forget-password/forget-password";
import { ResetPasswordPage } from "./pages/reset-password/reset-password";
import ErrorPage from "./components/error-boundary/error-element";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute component

export enum AppRoute {
  Home = "/",
  Form = "/documents/form",
  Dashboard = "/dashboard",
  Pdf = "/documents/pdf",
  Chat = "/chat/:chatId",
  Login = "/login",
  Signup = "/signup",
  ForgetPassword = "/account/forget-password",
  ResetPassword = "/account/reset",
}

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route>
      <Route path={AppRoute.Home} element={<HomePage />} />
      <Route path={AppRoute.Login} element={<LoginPage />} />
      <Route path={AppRoute.Signup} element={<SignupPage />} />
      <Route path={AppRoute.ForgetPassword} element={<ForgetPasswordPage />} />
      <Route path={AppRoute.ResetPassword} element={<ResetPasswordPage />} />
      <Route element={<PrivateRoute />}>
        <Route path={AppRoute.Dashboard} element={<DashboardPage />} />
        <Route path={AppRoute.Chat} element={<DashboardPage />} />
        <Route path={AppRoute.Form} element={<FormPage />} />
        <Route path={AppRoute.Pdf} element={<PdfPage />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Route>,
  ])
);

export const AppRoutes = () => <RouterProvider router={router} />;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { queryClient } from "..";
import { authService } from "../services/auth.service"; // Your service to call the backend
import { User } from "../types/user.type";
import { LoginData } from "../types/auth.types";

interface AuthContextType {
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  user: User | null;
  logout: () => Promise<void>;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the user on initial load
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await authService.getCurrentUser();

        setUser(currentUser);
      } catch (error) {
        setUser(null); // No user if not authenticated
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const logout = async () => {
    try {
      // await authService.logout(); // Backend clears the session/cookie
      setUser(null);
      queryClient.invalidateQueries();
      queryClient.clear();
      authService.logout();
      localStorage.clear();
    } catch (error) {
      console.error("error in logout");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show loading state while fetching user
  }

  return (
    <AuthContext.Provider value={{ user, setUser, isLoading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

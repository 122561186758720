import { useState, useEffect } from "react";
import { CheckIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../../lib/utils";
import { slideUpAndFade, fadeIn } from "../../utils/animation";
import { Progress } from "../ui/progress";
import { PdfIcon, StarInputIcon } from "../../assets";
import { documentService } from "../../services/document.service";
import { useGenerateDoc } from "../../context/DocContext";
import { OpenAIApiService } from "../../services/openai.service";
import { AppContext } from "../../context/AppContext";
import { useChat } from "../../context/ChatContext";
import { useContext } from "react";

interface ProgressStep {
  id: string;
  label: string;
  status: "pending" | "processing" | "completed";
  progress: number;
}

export default function DocumentProgress() {
  const [progressStatus, setProgressStatus] = useState<string>("pending");
  const [progressValue, setProgressValue] = useState<number>(0);
  const { hasStarted, setHasStarted, prompt } = useGenerateDoc();
  const [generate, setGenerate] = useState(false);

  const { activeChat } = useChat();
  const appContext = useContext(AppContext);
  const { formData, documentContent } = appContext!!;

  const [steps, setSteps] = useState<ProgressStep[]>([
    { id: "1", label: "Analysing", status: "pending", progress: 0 },
    { id: "2", label: "Processing", status: "pending", progress: 0 },
    { id: "3", label: "Ready to download", status: "pending", progress: 0 },
  ]);

  const isComplete = progressStatus === "completed";

  const setProgress = (status: string, value: number) => {
    setProgressStatus(status);
    setProgressValue(value);
  };

  useEffect(() => {
    setSteps((prevSteps) =>
      prevSteps.map((step) => {
        if (step.label === progressStatus) {
          return { ...step, status: "processing", progress: progressValue };
        } else if (step.progress >= 100) {
          return { ...step, status: "completed", progress: 100 };
        }
        return step;
      })
    );

    if (progressStatus === "Ready to download" && progressValue === 100) {
      setHasStarted(false); // Stop showing progress
    }
  }, [progressStatus, progressValue]);

  const handleGenerate = () => {
    console.log("Generating document in doc progress component...");
    setGenerate(true);
    triggerDocumentGeneration(prompt, documentContent, formData);
  };

  const simulateProgress = (status: string): Promise<void> => {
    return new Promise((resolve) => {
      let percentage = 0;
      const interval = setInterval(() => {
        percentage += 5;
        setProgress(status, percentage);

        if (percentage >= 100) {
          clearInterval(interval);
          setTimeout(resolve, 200); // small delay to let UI update
        }
      }, 100);
    });
  };

  const generateTitle = async (text: string) => {
    try {
      const response = await OpenAIApiService.generateTitleForDoc(text);
      console.log("Title response:", response);
      if (!response.title) {
        console.log("No title generated, using default");
        return "Generated Legal Document";
      }
      return response.title;
    } catch (error) {
      console.error("Error generating title:", error);
      return "";
    }
  };

  const triggerDocumentGeneration = async (
    prompt: string,
    document: string,
    data: any
  ) => {
    try {
      console.log("Triggering document generation...");
      console.log("Prompt:", prompt);
      console.log("Data:", data);
      setProgress("Analysing", 0);
      await simulateProgress("Analysing");

      const title = await generateTitle(prompt);

      const generatedMarkdown = await documentService.generateDocumentText({
        document,
        completedData: data,
      });

      setProgress("Processing", 0);
      await simulateProgress("Processing");

      console.log("sending document to generate...");
      console.log("Title:", title);
      console.log("chatId:", activeChat);
      await documentService.generateDocument({
        title,
        markdownContent: generatedMarkdown,
        chatId: activeChat || "",
      });

      setProgress("Ready to download", 0);
      await simulateProgress("Ready to download");

      setGenerate(false); // Reset generate button state
    } catch (error) {
      console.error("Error generating document:", error);
      setProgress("Error", 0);
    }
  };

  return (
    <div
      className="border border-gray-700 rounded-[0.75rem] p-[0.75rem] text-start bg-inherit text-white z-40"
      style={{
        border: "1px solid rgba(255, 255, 255, 0.06)",
      }}
    >
      <div className="flex justify-between items-center gap-[0.5rem] ">
        <motion.div
          className={`items-center ${
            isComplete ? "mb-0" : "mb-3"
          } flex flex-row gap-[0.5rem]`}
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <PdfIcon className="w-[1.5rem] h-[1.5rem]" />
          <p className="text-[0.875rem] m-0 p-0">Document</p>
        </motion.div>
        {hasStarted && (
          <button
            className="p-2 bg-[#6381FC] hover:bg-[#5272f3] rounded-[0.5rem] text-white cursor-pointer mb-2"
            onClick={handleGenerate}
            disabled={generate}
          >
            {generate ? "Generating..." : "Generate"}
          </button>
        )}
        {isComplete && <StarInputIcon className="w-[1.5rem] h-[1.5rem]" />}
      </div>

      {!isComplete && (
        <hr className="w-full bg-gray-600 border-[#2F2F37] border-[1px] border-solid mb-4"></hr>
      )}
      <AnimatePresence>
        {!isComplete && (
          <motion.div
            className="space-y-1"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideUpAndFade}
          >
            <h3 className="text-sm text-zinc-400 mb-4">Generating Progress</h3>

            <div className="relative pr-4">
              <div className="absolute left-[11px] top-4 w-[2px] h-[calc(100%-24px)] bg-zinc-800" />

              <div className="space-y-6">
                {steps.map((step, index) => (
                  <motion.div
                    key={step.id}
                    className="relative flex items-center"
                    initial="hidden"
                    animate="visible"
                    variants={slideUpAndFade}
                    transition={{ delay: index * 0.2 }}
                  >
                    <div
                      className={cn(
                        "w-6 h-6 rounded-full border-2 flex items-center justify-center z-10",
                        step.status === "completed"
                          ? "border-green-500 bg-green-500"
                          : step.status === "processing"
                          ? "border-[#6381FC] bg-transparent"
                          : "border-zinc-700 bg-transparent"
                      )}
                    >
                      {step.status === "completed" && (
                        <CheckIcon className="w-4 h-4 text-white" />
                      )}
                    </div>
                    <div className="ml-4 flex-1">
                      <div className="flex items-center justify-between">
                        <span
                          className={cn(
                            "text-sm font-medium",
                            step.status === "completed"
                              ? "text-green-500"
                              : step.status === "processing"
                              ? "text-[#6381FC]"
                              : "text-zinc-500"
                          )}
                        >
                          {step.label}
                        </span>
                        <span className="text-sm text-[#6381FC]">
                          {step.progress}%
                        </span>
                      </div>
                      {step.status === "processing" && (
                        <Progress
                          value={step.progress}
                          className="h-1 mt-2 bg-zinc-800"
                          indicatorClassName="bg-[#6381FC]"
                        />
                      )}
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

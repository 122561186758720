import { Link } from "react-router-dom";

export function PoweredByFooter() {
  return (
    <footer className="fixed bottom-4 right-4 z-50">
      <Link
        to="https://frankai.io/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm text-white transition-colors duration-200 ease-in-out no-underline"
      >
        Powered by AiFrank
      </Link>
    </footer>
  );
}

import React, { createContext, ReactNode, useState, useEffect } from "react";
import { Chat, Message, UpdateChat } from "../types/chat.types";
import { ChatService } from "../services/chat.service";
import { OpenAIApiService } from "../services/openai.service";
import { useLocalStorage } from "../hooks/use-local-storage";
import { useChats } from "../hooks/user-fetch-chats.hook";
import { useParams } from "react-router-dom";

interface ChatContextType {
  activeChat: string | null; // ID of the active chat
  setActiveChat: React.Dispatch<React.SetStateAction<string | null>>;
  chats: Chat[]; // Array of chat sessions
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>;
  generatedDocs: any[];
  loadingResponse: boolean;
  setLoadingResponse: React.Dispatch<React.SetStateAction<boolean>>;
  createChat: () => Promise<Chat>;
  updateChat: (props: UpdateChat) => Promise<{}>;
  fetchMessages: () => Promise<void>;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  loadingMessages: boolean;
}

export const ChatContext = createContext<ChatContextType | undefined>(
  undefined
);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { chatId } = useParams<{ chatId: string }>();
  const [activeChat, setActiveChat] = useState<string | null>(null);

  const [messages, setMessages] = useState<Message[]>([]);
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);

  const [generatedDocs, setGenerateDocs] = useState<any[]>([]);

  const [chats, setChats] = useState<Chat[]>(() => {
    const savedChats = localStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });

  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);

  const [activeThread, setActiveThread] = useLocalStorage("activeThread", "");
  const [activeAssistant, setActiveAssistant] = useLocalStorage(
    "activeAssistant",
    ""
  );
  const [activeVectorStore, setActiveVectorStore] = useLocalStorage(
    "activeVectorStore",
    ""
  );

  useEffect(() => {
    if (chatId) {
      console.log("triggered fetchMessages");
      fetchMessages();
    }
  }, [chatId]);

  // Save chats to local storage
  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  // Save activeChat to local storage
  useEffect(() => {
    if (activeChat) {
      localStorage.setItem("activeChat", activeChat);
    }
  }, [activeChat]);

  // Function to fetch messages for the active chat thread
  const fetchMessages = async () => {
    console.log("fetchMessages");
    const activeThread = localStorage.getItem("activeThread") || "";
    if (!activeThread) return;

    try {
      const response = await OpenAIApiService.listChatThreadMessages(
        activeThread.replace(/"/g, "")
      );
      // Map the API response to the Message type structure
      let fetchedMessages: Message[] = response.map((msg: any) => ({
        id: msg.id,
        role: msg.role,
        content: msg.content[0]?.text?.value || "",
        timestamp: new Date(msg.created_at * 1000), // Convert Unix timestamp to Date
        uploadedFiles: [], // Assuming no files are included in the example response
        appliedPromptId: msg.appliedPromptId,
        appliedPromptTitle: msg.appliedPromptTitle,
      }));

      // reverse the order of messages to show the latest message at the bottom
      fetchedMessages = fetchedMessages.reverse();

      const fetchedDocs = await fetchChatDocs(activeChat!!);
      if (fetchedDocs && fetchedDocs.uploadedDocs) {
        fetchedMessages = fetchedMessages.map((msg: any) => {
          const docs = fetchedDocs.uploadedDocs.filter(
            (doc: any) => doc.messageId === msg.id
          );
          return {
            ...msg,
            uploadedFiles: docs,
          };
        });
      }

      if (fetchedDocs && fetchedDocs.generatedDocs) {
        setGenerateDocs(fetchedDocs.generatedDocs);
      }

      setMessages(fetchedMessages);
      setChats((prevChats) => {
        return prevChats.map((chat) => {
          if (chat.id === activeChat) {
            return {
              ...chat,
              messages: fetchedMessages,
            };
          }
          return chat;
        });
      });
      console.log("fetchedMessages exit", fetchedMessages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchChatDocs = async (chatId: string) => {
    try {
      const response = await ChatService.getDocsByChatId(chatId);
      return response;
    } catch (error) {
      console.error("Error fetching chat documents:", error);
      return null;
    }
  };

  // Load activeChat on initialization
  useEffect(() => {
    const savedActiveChat = localStorage.getItem("activeChat");
    if (savedActiveChat) {
      setActiveChat(savedActiveChat);
    }
  }, []);

  const updateChat = async (props: UpdateChat) => {
    const response = await ChatService.updateChat(props);
    if (!response || !response.chatId) {
      throw new Error("Failed to update chat");
    }
    return response;
  };

  const createChat = async () => {
    const response = await ChatService.createChat();
    if (!response || !response.chatId) {
      throw new Error("Failed to create a new chat");
    }

    const [threadResponse, assistantResponse, vectorStoreResponse] =
      await Promise.all([
        OpenAIApiService.createThread(),
        OpenAIApiService.createAssistant(),
        OpenAIApiService.createVectorStore(response.chatId),
      ]);

    if (!threadResponse || !threadResponse.id) {
      throw new Error("Failed to create a new thread");
    }
    setActiveThread(threadResponse.id);

    if (!assistantResponse || !assistantResponse.id) {
      throw new Error("Failed to create a new assistant");
    }
    setActiveAssistant(assistantResponse.id);

    if (!vectorStoreResponse || !vectorStoreResponse.id) {
      throw new Error("Failed to create a new vector store");
    }
    setActiveVectorStore(vectorStoreResponse.id);

    await OpenAIApiService.associateVectorStore(
      assistantResponse.id,
      vectorStoreResponse.id
    );

    const newChat: Chat = {
      id: response.chatId,
      title: "New Chat",
      threadId: threadResponse.id,
      assistantId: assistantResponse.id,
      vectorStoreId: vectorStoreResponse.id,
      createdAt: new Date(),
      messages: [],
      updatedAt: new Date(),
    };
    setChats([...chats, newChat]);
    setActiveChat(newChat.id);

    await updateChat({
      chatId: newChat.id,
      chatTitle: newChat.title,
      assistantId: assistantResponse.id,
      threadId: threadResponse.id,
      vectorStoreId: vectorStoreResponse.id,
    });

    return newChat;
  };

  return (
    <ChatContext.Provider
      value={{
        generatedDocs,
        activeChat,
        setActiveChat,
        chats,
        setChats,
        loadingResponse,
        setLoadingResponse,
        createChat,
        updateChat,
        fetchMessages,
        messages,
        loadingMessages,
        setMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): ChatContextType => {
  const context = React.useContext(ChatContext);

  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

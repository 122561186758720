import { ApiService } from "./api.service";

export class OpenAIApiService {
  private static baseUrl = "openai";

  static async createAssistant() {
    return await ApiService.post<null, any>(
      `${this.baseUrl}/create-assistant`,
      null
    );
  }

  static async createVectorStore(chatId: string) {
    return await ApiService.post<{ chatId: string }, any>(
      `${this.baseUrl}/create-vector-store`,
      { chatId }
    );
  }

  static async uploadFiles(vectorStoreId: string, files: FileList) {
    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append("files", file));
    formData.append("vectorStoreId", vectorStoreId);

    return await ApiService.post<FormData, any>(
      `${this.baseUrl}/upload-file`,
      formData,
      true
    );
  }

  static async associateVectorStore(
    assistantId: string,
    vectorStoreId: string
  ) {
    return await ApiService.post<
      { assistantId: string; vectorStoreId: string },
      any
    >(`${this.baseUrl}/associate-vector-store`, {
      assistantId,
      vectorStoreId,
    });
  }

  static async createThread() {
    return await ApiService.post<null, any>(
      `${this.baseUrl}/create-thread`,
      null
    );
  }

  static async listChatThreadMessages(threadId: string) {
    return await ApiService.get<null, any>(
      `${this.baseUrl}/list-messages/?threadId=${threadId}`,
      null
    );
  }

  static async addMessage(threadId: string, message: string) {
    return await ApiService.post<{ threadId: string; message: string }, any>(
      `${this.baseUrl}/add-message`,
      { threadId, message }
    );
  }

  static async generateTitle(text: string) {
    return await ApiService.post<{ text: string }, any>(
      `${this.baseUrl}/generate-title`,
      { text }
    );
  }

  static async generateTitleForDoc(text: string) {
    return await ApiService.post<{ text: string }, any>(
      `${this.baseUrl}/generate-title-doc`,
      { text }
    );
  }

  static async sendMessage(
    threadId: string,
    assistantId: string,
    message: string
  ) {
    return await ApiService.post<{ message: string }, any>(
      `${this.baseUrl}/send-message/${threadId}/${assistantId}`,
      { message }
    );
  }

  static async generateForm(
    threadId: string,
    assistantId: string,
    message: string
  ) {
    console.log("Generating form with message in openai service:", message);
    console.log("Thread ID:", threadId);
    console.log("Assistant ID:", assistantId);
    console.log(
      `sending req to${this.baseUrl}/generate-form/${threadId}/${assistantId}`
    );
    return await ApiService.post<{ message: string }, any>(
      `${this.baseUrl}/generate-form/${threadId}/${assistantId}`,
      { message }
    );
  }

  static async streamAssistantResponse(threadId: string, assistantId: string) {
    return await ApiService.post<
      { threadId: string; assistantId: string },
      any
    >(`${this.baseUrl}/stream-response`, { threadId, assistantId });
  }
}

import { ApiService } from "./api.service";
import { UpdateChat } from "../types/chat.types";

export class ChatService {
  private static baseUrl = "chats"; // Define base URL for chat-related endpoints

  // Create a new chat session
  static async updateChat(props: UpdateChat) {
    return await ApiService.post<UpdateChat, { chatId: string }>(
      `${this.baseUrl}/update-chat`,
      {
        chatId: props.chatId,
        chatTitle: props.chatTitle,
        assistantId: props.assistantId,
        threadId: props.threadId,
        vectorStoreId: props.vectorStoreId,
      }
    );
  }

  // Create a new chat session
  static async createChat() {
    return await ApiService.post<null, { chatId: string }>(
      `${this.baseUrl}/create`,
      null
    );
  }

  static async updateTitle(chatId: string, title: string) {
    return await ApiService.post<{ chatId: string; title: string }, any>(
      `${this.baseUrl}/update-title`,
      { chatId, title }
    );
  }

  // Send a message to a chat
  static async sendMessage(
    chatId: string,
    content: string,
    documentId?: string
  ) {
    return await ApiService.post<
      { chatId: string; content: string; documentId?: string },
      any
    >(`${this.baseUrl}/add-message`, {
      chatId,
      content,
      documentId,
    });
  }

  static async addDocMetadata(
    chatId: string,
    messageId: string,
    documents: any
  ) {
    return await ApiService.post<
      { chatId: string; messageId: string; documents: any },
      any
    >(`${this.baseUrl}/associate-document`, {
      chatId,
      messageId,
      documents,
    });
  }

  static async getDocsByChatId(chatId: string) {
    return await ApiService.get<null, any>(
      `${this.baseUrl}/documents/${chatId}`,
      null
    );
  }

  // Get chat history by chatId
  static async getChatHistory(threadId: string) {
    return await ApiService.get<null, any>(
      `${this.baseUrl}/list-message/?threadId=${threadId}`,
      null
    );
  }

  // Get all chats for a specific user
  static async getChatsByUser() {
    return await ApiService.get<null, any>(`${this.baseUrl}/user`, null);
  }

  // Delete a chat by chatId
  static async deleteChat(chatId: string) {
    return await ApiService.delete<{ chatId: string }, any>(
      `${this.baseUrl}/${chatId}`,
      { chatId }
    );
  }
}

import Axios from "axios";

export enum ApiEndpoint {
  GetUser = "user",
  Register = "user/register",
  Login = "user/login",
  Logout = "user/logout",
  ForgotPassword = "user/forgot-password",
  ResetPassword = "user/reset-password",
  RefreshToken = "auth/refresh-token",
  Document = "documents",
  CreateDocument = "documents",
  UploadDocument = "documents/upload",
  GeneratePdf = "documents/generate",
  GetAllDocuments = "documents/all",
  GetDocumentById = "documents",
  UpdateDocument = "documents",
  DeleteDocument = "documents",
  GetDocumentsByChat = "chats/documents",
  GenerateDocumentForm = "documents/generate-form",
  checkGenerateDoc = "documents/check-generate-doc",
  GenerateDocumentText = "documents/generate-text",
  GetDocumentDownloadUrl = "documents",
  createAssistant = "openai/create-assistant",
  createVectorStore = "openai/create-vector-store",
  uploadFile = "openai/upload-file",
  associateVectorStore = "openai/associate-vector-store",
  createThread = "openai/create-thread",
  addMessage = "openai/add-message",
  streamAssistantResponse = "openai/stream-assistant-response",
}

class _ApiService {
  private apiUrl = process.env.REACT_APP_API_URL;
  private tokenKey = "accessToken"; // Key to store token in localStorage

  private axiosInstance = Axios.create({
    baseURL: this.apiUrl, // Ensure baseURL is set for all requests
  });

  constructor() {
    // Interceptor to handle default configurations
    this.axiosInstance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // GET request
  public async get<Request, Response>(
    endpoint: string,
    request: Request,
    requireAuth: boolean = true // Defaults to true
  ): Promise<Response> {
    const headers = requireAuth ? this.getAuthHeaders() : {};
    const response = await this.axiosInstance.get<Response>(endpoint, {
      params: request,
      headers,
    });
    return response.data;
  }

  // POST request
  public async post<Request, Response>(
    endpoint: string,
    request: Request,
    requireAuth: boolean = true // Defaults to true
  ): Promise<Response> {
    const headers = requireAuth ? this.getAuthHeaders() : {};
    const response = await this.axiosInstance.post<Response>(
      endpoint,
      request,
      {
        headers,
      }
    );
    return response.data;
  }

  public async getDocumentDownloadUrl(
    documentId: string,
    requireAuth: boolean = true
  ): Promise<{ url: string }> {
    const endpoint = `${ApiEndpoint.GetDocumentDownloadUrl}/${documentId}/url`;
    const headers = requireAuth ? this.getAuthHeaders() : {};
    const response = await this.axiosInstance.get<{ url: string }>(endpoint, {
      headers,
    });
    return response.data;
  }

  // PATCH request
  public async patch<Request, Response>(
    endpoint: string,
    request: Request,
    requireAuth: boolean = true // Defaults to true
  ): Promise<Response> {
    const headers = requireAuth ? this.getAuthHeaders() : {};
    const response = await this.axiosInstance.patch<Response>(
      endpoint,
      request,
      { headers }
    );
    return response.data;
  }

  // DELETE request
  public async delete<Request, Response>(
    endpoint: string,
    request: Request,
    requireAuth: boolean = true // Defaults to true
  ): Promise<Response> {
    const headers = requireAuth ? this.getAuthHeaders() : {};
    const response = await this.axiosInstance.delete<Response>(endpoint, {
      params: request,
      headers,
    });
    return response.data;
  }

  // Helper method to retrieve the auth token headers
  private getAuthHeaders(): Record<string, string> {
    const token = localStorage
      .getItem(this.tokenKey)
      ?.trim()
      .replace(/^"|"$/g, "");

    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return {};
  }

  // Logout and clear token from localStorage
  public logout() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem("userToken");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("currentStepIndex");
    localStorage.removeItem("currentStepData");
    localStorage.clear();
  }
}

export const ApiService = new _ApiService();

import React, { createContext, useState, useContext, ReactNode } from "react";

interface GenerateDocContextType {
  hasStarted: boolean;
  setHasStarted: (value: boolean) => void;
  prompt: string;
  setPrompt: (value: string) => void;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
}
const GenerateDocContext = createContext<GenerateDocContextType | undefined>(
  undefined
);

export const GenerateDocProvider = ({ children }: { children: ReactNode }) => {
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <GenerateDocContext.Provider
      value={{
        hasStarted,
        setHasStarted,
        prompt,
        setPrompt,
        showForm,
        setShowForm,
      }}
    >
      {children}
    </GenerateDocContext.Provider>
  );
};

export const useGenerateDoc = () => {
  const context = useContext(GenerateDocContext);
  if (!context) {
    throw new Error("useGenerateDoc must be used within a GenerateDocProvider");
  }
  return context;
};
